<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row" >
            <div class="col-lg-12" s>
                <h2 style="color : #ff5454">QUI SOMMES NOUS</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12"> 
                <div class="about-content">
                    <div class="section-title">
                        <h2>QUI SOMMES NOUS ?</h2>
                        <div class="bar"></div>
                        <p>Dans le monde effréné de la technologie de l'information, il est rare de trouver un phare d'innovation qui place également le client au centre de son univers. Nous, chez AGILISYS, sommes cette rareté.
                        </p>
                    </div>
                    <p>Établis avec un zèle pour la révolution technologique, notre société est à l'avant-garde de l'industrie marocaine du logiciel informatique depuis 2014, championne du développement de solutions centrées sur le client qui ne répondent pas seulement, mais anticipent les besoins de nos clients. Depuis notre fondation, nous avons été fidèles à la croyance que la technologie devrait servir de pont, et non de barrière, et notre gamme de produits reflète cette philosophie.
                    </p>
                    <p>Chaque ligne de code que nous écrivons, chaque interface utilisateur que nous concevons et chaque produit que nous lançons est imprégné de la promesse de fournir une expérience aussi intuitive qu'innovante. Notre dévouement à cet éthos dual de centralité du client et d'innovation de pointe forme la pierre angulaire de notre identité
                    </p>
                   
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/agilisys/a/wwr.jpg" alt="image" style="border-radius: 10% !important; ">
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/agilisys/a/wwr2.jpg" alt="image">
            </div>


            <div class="col-lg-6 col-md-12"> 
                <div class="about-content">
                    <div class="section-title">
                        <h2>NOTRE MISSION </h2>
                        <div class="bar"></div>
                        <p>Chez AGILISYS, notre mission est de percer la complexité de l'ère numérique pour présenter la technologie comme une extension naturelle des ambitions de nos clients. 

                        </p>
                    </div>
                    <p>Nous comprenons que dans le labyrinthe de l'IT moderne, la simplicité est la sophistication ultime. Notre objectif est de démystifier la technologie et de rendre les solutions informatiques puissantes non seulement accessibles, mais aussi adaptables aux défis et opportunités uniques que nos clients rencontrent. Nous nous efforçons de doter nos clients  d'outils qui parlent leur langue, en favorisant un environnement où la technologie accélère la croissance au lieu de la compliquer. 

                    </p>
                    <p>En fournissant des logiciels sur mesure qui s'alignent sur les stratégies commerciales, nous assurons que la technologie est un catalyseur pour l'innovation, stimulant l'efficacité et l'avantage concurrentiel. Notre engagement est d'être les architectes d'un paysage numérique où nos clients naviguent avec aisance et confiance, toujours soutenus par nos conseils d'experts.

                    </p>
                   
                </div>
            </div>

         
        </div>
    </div>
 
</section>
<!-- End About Area -->
<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Nos Valeurs</h2>
            <div class="bar"></div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="about-text">
                    <h3>Professionnalisme
                    </h3>
                    <p>Agilisys est avant tout un réseau d’Hommes engagés et passionnés, veillant à garantir la satisfaction des clients. 
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="about-text">
                    <h3>Intégrité
                    </h3>
                    <p>Respect de nos engagements vis-à-vis de nos clients avec un sens aigu d’honorer nos responsabilités  
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="about-text">
                    <h3>Transparence
                    </h3>
                    <p>Sincérité et ouverture au dialogue sont les points importants qui caractérisent la politique de communication de la société AGILISYS . 
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="about-text">
                    <h3>Innovation
                    </h3>
                    <p>Nous réinventons sans cesse nos méthodes de travail pour mieux satisfaire nos clients
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Want a bite? You're in the right place!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-end">
                <a routerLink="/contact-us" class="btn btn-primary">LET'S BUILD SOMETHING</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Notre Charte Satisfaction Client : 
            </h2>
            <div class="bar"></div>
            <p>5 principes fondamentaux qu’ AGILISYS s’engage à respecter
            </p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/agilisys/c/qualite.webp" alt="Qualité des missions">
                    <h3>Qualité des missions
                    </h3>
                </div>
                <p>Chaque mission réalisée doit apporter une réponse entièrement satisfaisante aux attentes du client.
                </p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/agilisys/c/engagement.webp" alt="Engagement total">
                    <h3>Engagement total
                    </h3>
                </div>
                <p>Une mission n’est considérée terminée que lorsque le client est pleinement satisfait</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/agilisys/c/valeur.webp" alt="Apport de valeur">
                    <h3>Apport de valeur
                    </h3>                </div>
                <p>Agilisys doit s’assurer de la valeur apportée à ses clients.
                </p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/agilisys/c/transparence.webp" alt="Transparence & dialogue">
                    <h3>Transparence & dialogue
                    </h3>
                </div>
                <p>Notre client doit, en permanence, être en situation de dialoguer en confiance avec nos consultants.
                </p>
            </div>
            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/agilisys/c/communication.webp" alt="Ecoute active & action">
                    <h3>Ecoute active & action

                    </h3>
                </div>
                <p>Tout écart par rapport à l’entière satisfaction du client doit donner lieu à une analyse et, le cas échéant, à une action corrective.

                </p>
            </div>

        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<app-partner></app-partner>
