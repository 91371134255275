<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 style="color: #ff5454">Contacter Nous</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-address-book"></i>
                    </div>
                    <h3>Affaires :</h3>
                    <p>
                        Pour les demandes liées aux services AGILISYS, veuillez
                        nous contacter à:
                    </p>

                    <p>
                        <a href="mailto:sales@agilisys.ma"
                            >sales&#64;agilisys.ma</a
                        >
                    </p>
                    <p><a href="tel:+212619826933">+(212) 619826933</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-address-book"></i>
                    </div>
                    <h3>Carrière et Recrutement:</h3>
                    <p>
                        Pour les demandes liées aux opportunités de carrière et
                        de recrutement
                    </p>
                    <p>
                        <a href="mailto:rh@agilisys.ma">rh&#64;agilisys.ma</a>
                    </p>
                    <br />
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-info-circle"></i>
                    </div>
                    <h3>Autres :</h3>
                    <p>Pour vos autres demandes</p>
                    <p>
                        <a href="mailto:info@agilisys.ma"
                            >info&#64;agilisys.ma</a
                        >
                    </p>
                    <br /><br />
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-network"></i>
                    </div>
                    <h3>Bureau Casablanca :</h3>
                    <p>Residence Hajar ,n 27 etage 5 ,la gironde Casablanca</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-network"></i>
                    </div>
                    <h3>Bureau Rabat :</h3>
                    <p>
                        15 avenue ALABTAL appartement 4 ,4 émet étage Rabat
                        Maroc
                    </p>
                    <br />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info Area -->

<!-- Start Contact Area 
<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Get In Touch With Us</h2>
            <div class="bar"></div>
            <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <img src="assets/img/marketing.png" alt="image">
            </div>

            <div class="col-lg-6 col-md-12">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" class="form-control" required placeholder="Name">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="email" class="form-control" required placeholder="Email">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Phone">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>-->
<!-- End Contact Area -->
