import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";

@Component({
    selector: "app-digital-marketing",
    templateUrl: "./digital-marketing.component.html",
    styleUrls: ["./digital-marketing.component.scss"],
})
export class DigitalMarketingComponent implements OnInit {
    constructor(private titleService: Title) {
        this.titleService.setTitle(
            "Agilisys Maroc - Home"
        );
    }


    ngOnInit() {
    }
}
