<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 style="color: #ff5454">
                    Nos Clients Qui Nous Ont Fait Confiance
                </h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
</section>
<!-- End Page Title Area -->
<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Témoignages</h2>
            <div class="bar"></div>
            <p>Ce que disent nos clients de nous ...</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/clients/7.webp" alt="Sindipark" />
                    <h3>Ahmed Abu Alathae</h3>
                    <span>Responsable achat et finance Sindipark </span>
                </div>
                <p>
                    L'équipe AGILISYS a transformé notre vision en réalité avec
                    une solution parfaitement adaptée à nos besoins uniques.
                    Leur approche personnalisée et leur souci du détail ont
                    significativement amélioré notre efficacité opérationnelle.
                    Leur réactivité face à nos demandes et leur capacité à
                    résoudre les problèmes rapidement ont été particulièrement
                    impressionnantes. Un partenaire de choix pour toute
                    entreprise à la recherche de solutions informatiques sur
                    mesure
                </p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/clients/4.webp" alt="GPS" />
                    <h3>Saad El KENZI</h3>
                    <span>Co-fondateur GPS </span>
                </div>
                <p>
                    En tant que startup, nous avions besoin d'un partenaire
                    capable de s'adapter rapidement à notre croissance et à nos
                    évolutions. AGILISYS a dépassé toutes nos attentes, en
                    livrant un produit exceptionnel qui a joué un rôle clé dans
                    notre succès. Leur flexibilité, leur engagement et leur
                    expertise technique sont inégalés. Ils ont véritablement
                    compris notre vision et l'ont concrétisée
                </p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img
                        src="assets/img/clients/6.webp"
                        alt="Sindibad Beach Resort"
                    />
                    <h3>Widad Hjiaj</h3>
                    <span>Directrice Commerciale Sindibad Beach Resort </span>
                </div>
                <p>
                    Ce qui distingue AGILISYS, c'est leur capacité à résoudre
                    des problèmes complexes de manière créative. Le logiciel
                    qu'ils ont développé pour nous est devenu essentiel à notre
                    opération. Ils ont une forte culture de l'innovation et une
                    réelle volonté de pousser les limites de ce qui est
                    techniquement possible. Leur engagement envers la qualité et
                    l'excellence est évident dans tout ce qu'ils font. Un
                    partenaire exceptionnel pour toute entreprise en quête de
                    solutions logicielles avancées
                </p>
            </div>
        </div>
    </div>

    <svg
        class="svg-feedback-bottom"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
    >
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff" />
    </svg>
</section>
<!-- Start Gallery Area -->
<section class="gallery-area ptb-100">
    <div class="section-title">
        <h2>Ils Nous ont fait Confiance</h2>
        <div class="bar"></div>
    </div>

    <div class="row m-0 desktop">
        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/1.png" alt="gallery" />
                <a href="assets/img/clients/1.png" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/2.webp" alt="NSI" />
                <a href="assets/img/clients/2.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/3.png" alt="SGMA" />
                <a href="assets/img/clients/3.png" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/4.webp" alt="gallery" />
                <a href="assets/img/clients/4.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img
                    src="assets/img/clients/5.webp"
                    alt="Sindibad beach resort"
                />
                <a href="assets/img/clients/5.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/6.png" alt="sindiparc" />
                <a href="assets/img/clients/6.png" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/7.png" alt="ocp safi" />
                <a href="assets/img/clients/7.png" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/8.png" alt="gallery" />
                <a href="assets/img/clients/8.png" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/9.webp" alt="dar al amane" />
                <a href="assets/img/clients/9.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/10.webp" alt="gallery" />
                <a href="assets/img/clients/10.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/12.webp" alt="sapst" />
                <a href="assets/img/clients/12.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/clients/13.webp" alt="rcar" />
                <a href="assets/img/clients/13.webp" class="popup-btn"
                    ><i class="icofont-plus"></i
                ></a>
            </div>
        </div>
        <!--
            <div class="col-lg-3 col-md-6 p-0">
                <div class="single-image">
                    <img src="assets/img/gallery5.jpg" alt="gallery">
                    <a href="assets/img/gallery5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
    
            <div class="col-lg-3 col-md-6 p-0">
                <div class="single-image">
                    <img src="assets/img/gallery6.jpg" alt="gallery">
                    <a href="assets/img/gallery6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
    
            <div class="col-lg-3 col-md-6 p-0">
                <div class="single-image">
                    <img src="assets/img/gallery7.jpg" alt="gallery">
                    <a href="assets/img/gallery7.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>
    
            <div class="col-lg-3 col-md-6 p-0">
                <div class="single-image">
                    <img src="assets/img/gallery8.jpg" alt="gallery">
                    <a href="assets/img/gallery8.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                </div>
            </div>-->
    </div>

    <div class="partner-area mobile">
        <div class="container">
            <div class="partner-slides owl-carousel owl-theme">
                <div class="item">
                    <img
                        src="assets/img/clients/1.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/2.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/3.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/4.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/5.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/6.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>
                <div class="item">
                    <img
                        src="assets/img/clients/7.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/8.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>

                <div class="item">
                    <img
                        src="assets/img/clients/9.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>
                <div class="item">
                    <img
                        src="assets/img/clients/10.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>
                <div class="item">
                    <img
                        src="assets/img/clients/13.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>
                <div class="item">
                    <img
                        src="assets/img/clients/12.webp"
                        alt="partner"
                        width="100px"
                        height="200px"
                    />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->
