<!-- Start Sidebar Modal -->
<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="icofont-close"></i></span></button>
                    <h2 class="modal-title" id="myModalLabel2"><a routerLink="/"><img src="assets/img/agilisys/logot.png" alt="logo"></a></h2>
                </div>

                <div class="modal-body">
                  <!--  <div class="sidebar-modal-widget">
                        <h3 class="title">Additional Links</h3>

                        <ul>
                            <li><a routerLink="/">Login</a></li>
                            <li><a routerLink="/">Register</a></li>
                            <li><a routerLink="/">FAQ</a></li>
                            <li><a routerLink="/">Logout</a></li>
                        </ul>
                    </div>-->

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="icofont-google-map"></i>
                                Addresse 
                                <span>15 avenue ALABTAL appartement 4 ,4 émet étage Rabat Maroc
                                </span>
                            </li>
                            <li>
                                <i class="icofont-email"></i>
                                Email
                                <span><a href="mailto:sales@agilisys.ma">sales&#64;agilisys.ma</a></span>
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                Phone
                                <span><a href="tel:+212619826933">+(212) 619826933</a></span>
                            </li>
                        </ul>
                    </div>

                   <!-- <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->