<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <img src="assets/img/clients/1.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/2.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/3.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/4.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/5.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/6.webp" alt="partner" width="100px" height="200px"/>
            </div>
            <div class="item">
                <img src="assets/img/clients/7.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/8.webp" alt="partner" width="100px" height="200px"/>
            </div>

            <div class="item">
                <img src="assets/img/clients/9.webp" alt="partner" width="100px" height="200px"/>
            </div>
            <div class="item">
                <img src="assets/img/clients/10.webp" alt="partner" width="100px" height="200px"/>
            </div>
            <div class="item">
                <img src="assets/img/clients/13.webp" alt="partner" width="100px" height="200px"/>
            </div>
            <div class="item">
                <img src="assets/img/clients/12.webp" alt="partner" width="100px" height="200px"/>
            </div>



        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="{ slidesToShow: 4, slidesToScroll: 4 }"
>
    <div ngxSlickItem class="slide">
        <img src="assets/img/clients/1.webp" alt="" width="100px"  height="200px"/>
    </div>

    <div ngxSlickItem class="slide">
        <img src="assets/img/clients/2.webp" alt="" width="100px" height="200px"/>
    </div>

    <div ngxSlickItem class="slide">
        <img src="assets/img/clients/3.webp" alt="" width="100px" height="200px"/>
    </div>

    <div ngxSlickItem class="slide">
        <img src="assets/img/clients/4.webp" alt="" width="100px" height="200px"/>
    </div>

    <div ngxSlickItem class="slide">
        <img src="assets/img/clients/5.webp" alt="" width="100px" height="200px"/>
    </div>
</ngx-slick-carousel> -->
