<!-- Start Software Main Banner -->
<div class="main-banner marketing-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>EDI PORTNET</h1>
                            <p>
                                Intègre automatiquement vos Domiciliations de
                                Portnet, assure un contrôle technique des flux,
                                facilite le traitement des opérations via une
                                IHM conviviale et intuitive et vous assure un
                                monitoring et un suivi rapproché de votre chaîne
                                de traitement.
                            </p>
                            <!-- <a
                                href="/assets/media/swiftBridge_brochure.pdf"
                                download="swiftBridge_brochure"
                                class="btn btn-primary"
                                >EDI PORTNET Brochure</a
                            > -->
                            <a
                                routerLink="/contact-us"
                                class="btn btn-primary"
                            >
                                Contactez Nous</a
                            >
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <!-- <div class="signup-download ml-5">
                            <form>
                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" class="form-control" placeholder="Enter your username">
                                </div>

                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Enter your email">
                                </div>

                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control" placeholder="Enter your password">
                                </div>

                                <button type="submit" class="btn btn-primary">Sign Up</button>
                            </form>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg5.png" alt="bg" /></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>

    <!-- Bubble Animte -->
    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</div>
<!-- End Software Main Banner -->

<!-- <app-partner></app-partner> -->

<!-- Start Features Area -->
<section class="features-area software-features ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>
                DISTINGUEZ-VOUS DE VOS CONFRÈRES AVEC UN TRAITEMENT RAPIDE ET
                EFFICACE DES DOMICILIATIONS
            </h2>
            <div class="bar"></div>
            <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fas fa-code"></i>
                    </div>
                    <h3>Interfaçage avec Portnet</h3>
                    <p>
                        Intégration fluide de l’ensemble de vos flux de Portnet
                        avec un contrôle technique automatique en plus des
                        retours après dénouement des opérations.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fas fa-robot"></i>
                    </div>
                    <h3>Automatisation des Contrôles</h3>
                    <p>
                        Gestion en automatique de vos contrôles règlementaires
                        et Compliance à travers l’implémentation de vos règles
                        de gestion mais également via un interfaçage avec des
                        systèmes tiers de la Banque ou Partenaires, garantissant
                        une optimisation des délais de traitement et une
                        meilleure maîtrise des risques opérationnels.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>Efficacité Opérationnelle</h3>
                    <p>
                        Solution qui s’adapte à votre organisation en termes
                        d’équipes, process de traitement et règles d’affectation
                        en automatique pour une efficacité opérationnelle
                        garantie.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa-solid fa-gears"></i>
                    </div>
                    <h3>Workflow de traitement personnalisés</h3>
                    <p>
                        Fonctionnalités avancées pour créer des workflows BPM
                        (Business Process Management) personnalisés, permettant
                        de gérer et de traiter les flux Portnet de manière
                        flexible et efficace, avec possibilité de traiter
                        certains flux en Full automatique (Annulation,
                        Impoutations,…)
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa-solid fa-magnifying-glass-chart"></i>
                    </div>
                    <h3>Monitoring en temps réel</h3>
                    <p>
                        Monitoring de votre activité opérationnelle en temps
                        réel grâce à des dashbords interactifs, offrant une
                        visibilité complète sur vos flux et leur statut
                        d’intégration.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa-solid fa-globe"></i>
                    </div>
                    <h3>Interfaçage API flexible</h3>
                    <p>
                        Intégration facile avec vos systèmes opérants pour une
                        gestions fluide du cycle de vie des titres
                        d’importations tout le long de votre chaîne de
                        traitement Trade.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image" />
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Free Caliing Service</h3>
                            <p>
                                Plan ahead by day, week, or month, and see
                                project status at a glance. Search and filter to
                                focus in on anything form a single project to an
                                individual person's workload.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>
                                Plan ahead by day, week, or month, and see
                                project status at a glance. Search and filter to
                                focus in on anything form a single project to an
                                individual person's workload.
                            </p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>
                                Plan ahead by day, week, or month, and see
                                project status at a glance. Search and filter to
                                focus in on anything form a single project to an
                                individual person's workload.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape" /></div>
    <div class="shape11 rotateme">
        <img src="assets/img/shape11.svg" alt="shape" />
    </div>
</section>
<!-- End Features Area -->

<!-- Start Overview Area -->
<!-- <section class="overview-section software-overview ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image" />
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <span>Now 2x faster</span>
                    <h3>Stay safer and more private</h3>
                    <p>
                        Plan ahead by day, week, or month, and see project
                        status at a glance. Search and filter to focus in on
                        anything form a single project to an individual person's
                        workload.
                    </p>
                    <ul class="features-list">
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Overview Area -->

<!-- Start Overview Area -->
<!-- <section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <span>Notification Alerts</span>
                    <h3>A faster way to do just about anything</h3>
                    <p>
                        Plan ahead by day, week, or month, and see project
                        status at a glance. Search and filter to focus in on
                        anything form a single project to an individual person's
                        workload.
                    </p>
                    <ul class="features-list">
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-2.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Overview Area -->

<!-- Start Overview Area -->
<!-- <section class="overview-section software-overview ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-3.png" alt="image" />
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <span>New Browser</span>
                    <h3>Google smarts for everything you want to do</h3>
                    <p>
                        Plan ahead by day, week, or month, and see project
                        status at a glance. Search and filter to focus in on
                        anything form a single project to an individual person's
                        workload.
                    </p>
                    <ul class="features-list">
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Overview Area -->

<!-- Start Overview Area -->
<!-- <section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <span>Lightweight</span>
                    <h3>Everything you nedd, one swipe away.</h3>
                    <p>
                        Plan ahead by day, week, or month, and see project
                        status at a glance. Search and filter to focus in on
                        anything form a single project to an individual person's
                        workload.
                    </p>
                    <ul class="features-list">
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-4.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Overview Area -->

<!-- Start CTA Area -->
<!-- <div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Do more on the web, with a fast and secure browser!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-end">
                <a routerLink="/contact-us" class="btn btn-primary"
                    >Take a Test Drive</a
                >
            </div>
        </div>
    </div>
</div> -->
<!-- End CTA Area -->

<!-- Start SAAS Tools Area -->
<!-- <section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>CONÇU POUR ÉVOLUER AVEC VOUS</h2>
            <div class="bar"></div>
        </div>

        <div class="tab">
            <ul class="tabs">
                <li>
                    <a href="#">
                        <i class="icofont-dashboard-web"></i>
                        <br />
                        Scalabilité
                    </a>
                </li>

                <li>
                    <a href="#">
                        <i class="icofont-inbox"></i>
                        <br />
                        Modularité
                    </a>
                </li>

                <li>
                    <a href="#">
                        <i class="icofont-ui-calendar"></i>
                        <br />
                        Interfaçage
                    </a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div
                        class="row h-100 justify-content-center align-items-center"
                    >
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Scalabilité</h3>
                                <p>
                                    SwiftBridge avec son architecture scalable
                                    est capable de gérer des volumes de
                                    transactions massifs sans compromettre les
                                    performances. La plateforme évolue avec
                                    votre institution, augmentant les capacités
                                    en réponse à la hausse des transactions et
                                    des utilisateurs. Cette scalabilité garantit
                                    une robustesse et une fiabilité optimales
                                    pour un traitement continu et efficace des
                                    paiements.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/rtgs/1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div
                        class="row h-100 justify-content-center align-items-center"
                    >
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/rtgs/2.png" alt="img" />
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mb-0">
                                <h3>Modularité</h3>
                                <p>
                                    L'architecture modulaire de SwiftBridge
                                    permet une personnalisation rapide des
                                    fonctionnalités pour répondre aux besoins
                                    spécifiques des institutions financières.
                                    Elle facilite la personnalisation des
                                    modules selon les exigences changeantes,
                                    minimisant ainsi les coûts et le temps
                                    d’implémentation. Cette modularité assure
                                    que le système reste agile et capable de
                                    s’adapter aux évolutions rapides du marché.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div
                        class="row h-100 justify-content-center align-items-center"
                    >
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Interfaçage</h3>
                                <p>
                                    SwiftBridge offre des options d'interfaçage
                                    polyvalentes via des API RESTful et des
                                    queues de messages (MQ), permettant une
                                    intégration fluide avec vos systèmes
                                    existants. Les API RESTful assurent une
                                    communication simple et rapide entre les
                                    applications, garantissant une mise en œuvre
                                    sans friction. Les queues de messages (MQ)
                                    offrent une robustesse accrue pour gérer des
                                    volumes élevés de messages et garantir une
                                    transmission fiable des données, même lors
                                    de pics de charge.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/rtgs/3.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End SAAS Tools Area -->

<!-- Start App Funfacts Area -->
<!-- <section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-download"></i>
                    </div>
                    <h3><span class="count">1.5</span>B</h3>
                    <p>Download</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-star"></i>
                    </div>
                    <h3><span class="count">4.8</span></h3>
                    <p>Rating</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-user-suited"></i>
                    </div>
                    <h3><span class="count">500</span>M</h3>
                    <p>Active User</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3><span class="count">13</span>K</h3>
                    <p>Updates</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Funfacts Area -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-area ptb-100 bg-gray">
    <div class="fun-facts-inner-area mt-0">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="fun-facts-image">
                        <img src="assets/img/features-img1.png" alt="image" />
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="fun-facts-inner-content">
                        <h3>Build Beautiful Interface Into Your Application</h3>
                        <p>
                            Funding freemium technology focus equity
                            bootstrapping usernce niche market. Seed round agile
                            development growth hacking retur investment alpha.
                            Investor advisor marketing pitch gen scrum project.
                        </p>
                        <p>
                            Responsive web design. Agile development innovator
                            termsheet is users interface pitch scrum project
                            research & development.
                        </p>
                        <ul>
                            <li>Unimited Video Call</li>
                            <li>Add Favourite contact</li>
                            <li>Camera Filter</li>
                        </ul>
                        <a routerLink="/about-us" class="btn btn-primary"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Start Get Started Area -->
<!-- <section class="get-started ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Get Started Free</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="get-started-form">
            <form>
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                    />
                </div>

                <div class="form-group">
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                    />
                </div>

                <div class="form-group">
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                    />
                </div>

                <button type="submit" class="btn btn-primary">Sign Up</button>
                <p>or use your <a href="#">Facebook Account</a></p>
            </form>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section> -->
<!-- End Get Started Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img1.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 23, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Most Popular New Business Apps</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img2.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 16, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Best Marketing Management Tools</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img3.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 14, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >3 WooCommerce Plugins to Boost Sales</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img4.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 06, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >CakeMail Review – Design Custom Emails</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img5.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 04, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Most Popular New Apps in 2024</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"
                    ><img src="assets/img/blog-img3.jpg" alt="blog-image"
                /></a>

                <div class="blog-post-content">
                    <ul>
                        <li>
                            <i class="icofont-user-male"></i>
                            <a routerLink="/blog">Admin</a>
                        </li>
                        <li>
                            <i class="icofont-wall-clock"></i> January 26, 2024
                        </li>
                    </ul>
                    <h3>
                        <a routerLink="/blog-details"
                            >The Fastest Growing Apps in 2024</a
                        >
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi turpis massa, dapibus nec libero vitae.
                    </p>
                    <a routerLink="/blog-details" class="read-more-btn"
                        >Read More <i class="icofont-rounded-double-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start App Download Area -->
<!-- <section class="app-download ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6">
                <div class="section-title">
                    <h2>Download Our Software Today</h2>
                    <div class="bar"></div>
                    <p>
                        But I must explain to you how all this mistaken idea of
                        denouncing pleasure and praising pain was born and I
                        will give you a complete account the system and expound
                        the actual teachings of the great explore.
                    </p>
                    <div class="download-btn">
                        <a href="#" target="_blank"
                            ><i class="icofont-brand-windows"></i> Available On
                            <span>Windows</span></a
                        >
                        <a href="#" target="_blank"
                            ><i class="icofont-brand-linux"></i> Available On
                            <span>Linux</span></a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-image">
                    <img src="assets/img/app-img1.png" alt="image" />
                    <img src="assets/img/app-img2.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Download Area -->

<!-- Start Platform Connect -->
<!-- <section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/mailchimp.png" alt="mailchimp" />
                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/slack.png" alt="mailchimp" />
                    <h3>Slack</h3>
                    <span>Messaging</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/twitter.png" alt="mailchimp" />
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/instagram.png" alt="mailchimp" />
                    <h3>Instagram</h3>
                    <span>News Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section> -->
<!-- End Platform Connect -->
