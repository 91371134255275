import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-blog-details",
    templateUrl: "./blog-details.component.html",
    styleUrls: ["./blog-details.component.scss"],
})
export class BlogDetailsComponent implements OnInit {
    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.titleService.setTitle("Agilisys Maroc - Success Story");
    }

    private params_subscription: any;
    
    id !: string

    ngOnInit() {
        this.params_subscription = this.route.params.subscribe((params) => {
            this.id = params["id"];
        });
    }
}
