import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-SwiftBridge-landing",
    templateUrl: "./Kyc.component.html",
    styleUrls: ["./Kyc.component.scss"],
})
export class KYCComponent implements OnInit {
    constructor(private titleService: Title, private meta: Meta) {
        this.titleService.setTitle("Agilisys Maroc - KYC Client");

        this.meta.updateTag({
            name: "description",
            content:
                "KYC Client centralise les processus KYC pour des revues exhaustives et conformes, intégrant le scoring et le filtrage.",
        });

        this.meta.updateTag({
            name: "keywords",
            content:
                "KYC Client centralise les processus KYC pour des revues exhaustives et conformes, intégrant le scoring et le filtrage.",
        });
    }

    ngOnInit() {}
}
