<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 style="color: #ff5454">Notre Success Story</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img" /></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img" /></div>
    <div class="shape8 rotateme">
        <img src="assets/img/shape8.svg" alt="shape" />
    </div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape" /></div>
</section>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details/compliflow" class="post-image"
                        ><img
                            src="assets/img/compliflow-noBG.png"
                            style="width: 100%"
                            height="244"
                            alt="blog-image"
                    /></a>

                    <div class="blog-post-content">
                        <ul>
                            <!-- <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li> -->
                            <li><i class="fa fa-calendar"></i> 03/2023</li>
                        </ul>
                        <h3>
                            <a routerLink="/blog-details/compliflow">
                                Optimisation des contrôles Conformité et
                                Règlementaires avec Compliflow</a
                            >
                        </h3>
                        <p>
                            Dans un environnement bancaire de plus en plus
                            réglementer, les banques locales faisaient face à
                            des défis complexes pour garantir la conformité, en
                            particulier sur le suivi des sanctions et embargos,
                            avec un processus manuel chronophage et sujet à
                            erreurs.
                        </p>
                        <a routerLink="/blog-details/compliflow" class="read-more-btn"
                            >Lire la suite
                            <i class="icofont-rounded-double-right"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details/domiflow" class="post-image"
                        ><img
                            src="assets/img/domiflow.png"
                            style="width: 100%"
                            height="244"
                            alt="blog-image"
                    /></a>

                    <div class="blog-post-content">
                        <ul>
                            <!-- <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li> -->
                            <li><i class="fa fa-calendar"></i> 02/2023</li>
                        </ul>
                        <h3>
                            <a routerLink="/blog-details/domiflow"
                                >Transformation Digitale du processus de
                                traitement des domiciliations avec DomFlow</a
                            >
                        </h3>
                        <p>
                            Face à des besoins croissants en matière de
                            digitalisation et d’optimisation des flux de
                            données, plusieurs banques locales ont exprimé le
                            besoin d’un système moderne et automatiséla gestion
                            du cycle de vie des domiciliations de manière plus
                            fluide et sécurisée.
                        </p>
                        <a routerLink="/blog-details/domiflow" class="read-more-btn"
                            >Lire la suite
                            <i class="icofont-rounded-double-right"></i
                        ></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details/pain001" class="post-image"
                        ><img
                            src="assets/img/iso20022-no-BG.png"
                            style="width: 100%"
                            height="244"
                            alt="blog-image"
                    /></a>

                    <div class="blog-post-content">
                        <ul>
                            <!-- <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li> -->
                            <li><i class="fa fa-calendar"></i> 12/2022</li>
                        </ul>
                        <h3>
                            <a routerLink="/blog-details/pain001"
                                >Pain001 Core Banking Integration</a
                            >
                        </h3>
                        <p>
                            Face aux besoins accrus de digitalisation et
                            d'efficacité opérationnelle, plusieurs banques
                            locales ont adopté notre solution automatisée pour
                            générer et intégrer les messages PAIN.001 de manière
                            fluide et conforme aux normes ISO 20022.
                        </p>
                        <a routerLink="/blog-details/pain001" class="read-more-btn"
                            >Lire la suite
                            <i class="icofont-rounded-double-right"></i
                        ></a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 06, 2024</li>
                        </ul>
                        <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Lire la suite <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 04, 2024</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Most Popular New Apps in 2024</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Lire la suite <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                    <div class="blog-post-content">
                        <ul>
                            <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                            <li><i class="icofont-wall-clock"></i> January 26, 2024</li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2024</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Lire la suite <i class="icofont-rounded-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class="icofont-double-left"></i></a></li>
                            <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class="icofont-double-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Blog Area -->
