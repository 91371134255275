<!-- Start Product Main Banner -->
<div class="main-banner product-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Monitoring Your Daily Life</h1>
                            <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>
                            <a routerLink="/" class="btn btn-primary">Buy Now</a>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="product-image">
                            <img src="assets/img/watch-1.png" alt="watch">
                            <img src="assets/img/watch-2.png" alt="watch">
                            <div class="offer-badge">
                                50%
                                <span>OFF</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg4.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
</div>
<!-- End Product Main Banner -->

<!-- Start Features Area -->
<section class="features-area product-features ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>A Watch Can Make Your Life Easy and Comfortable</h2>
                        <div class="bar"></div>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                    </div>
                    <p>We’re also experts at finding the sweet spot between Google’s guidelines and what is commercially right for you. We have progressive theories on search as a tool for retention of customers, not just for acquisition. we always measure, always analyze and always innovate.</p>
                    <a routerLink="/services" class="btn btn-primary">Our Services</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box mt-50">
                            <img src="assets/img/watch-1.png" alt="image">
                            <h3>Camera Resulation</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/watch-2.png" alt="image">
                            <h3>High Speed Shooting</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img src="assets/img/watch-2.png" alt="image">
                            <h3>Powerful Control Panel</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/watch-1.png" alt="image">
                            <h3>Healthy Battery Life</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-download"></i>
                            </div>
                            <h3>Install Apps</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-tracking"></i>
                            </div>
                            <h3>GPS Tracking</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>QR Code Scaner</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image text-center">
                        <img src="assets/img/watch-1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start New Features Area -->
<section class="new-features-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="new-features-img">
                    <img src="assets/img/feature-img-with-app.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="new-features-content">
                    <div class="section-title">
                        <h2>Know yourself, Know your health</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="single-inner-features">
                        <i class="icofont-download"></i>
                        <h3>Download App</h3>
                        <p>Progressively expedite bleeding-edge catalysts for change via resource sucking channels. Energistically expedite.</p>
                    </div>

                    <div class="single-inner-features">
                        <i class="icofont-bluetooth"></i>
                        <h3>Bluetooth Data Sync</h3>
                        <p>Progressively expedite bleeding-edge catalysts for change via resource sucking channels. Energistically expedite.</p>
                    </div>

                    <div class="single-inner-features">
                        <i class="icofont-ui-weather"></i>
                        <h3>Weather Update</h3>
                        <p>Progressively expedite bleeding-edge catalysts for change via resource sucking channels. Energistically expedite.</p>
                    </div>

                    <div class="single-inner-features">
                        <i class="icofont-blood-drop"></i>
                        <h3>Blood Pressure Management</h3>
                        <p>Progressively expedite bleeding-edge catalysts for change via resource sucking channels. Energistically expedite.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End New Features Area -->

<!-- Start Next Generation Area -->
<section class="next-generation-virtual-reality ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Next-generation virtual Reality</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-item">
                    <div class="icon">
                        <i class="icofont-listine-dots"></i>
                    </div>
                    <h3>Mobile Interface Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean gravida leo porttitor pretium ullamcorper. Fusce consectetur turpis nec dolor laoreet ultrices. In a venenatis leo.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-item">
                    <div class="icon">
                        <i class="icofont-camera-alt"></i>
                    </div>
                    <h3>User Experience</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean gravida leo porttitor pretium ullamcorper. Fusce consectetur turpis nec dolor laoreet ultrices. In a venenatis leo.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-item">
                    <div class="icon">
                        <i class="icofont-responsive"></i>
                    </div>
                    <h3>Web and Mobile Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean gravida leo porttitor pretium ullamcorper. Fusce consectetur turpis nec dolor laoreet ultrices. In a venenatis leo.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-item">
                    <div class="icon">
                        <i class="icofont-brand-fastrack"></i>
                    </div>
                    <h3>Project Strategy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean gravida leo porttitor pretium ullamcorper. Fusce consectetur turpis nec dolor laoreet ultrices. In a venenatis leo.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-item">
                    <div class="icon">
                        <i class="icofont-sand-clock"></i>
                    </div>
                    <h3>Full Demo Content</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean gravida leo porttitor pretium ullamcorper. Fusce consectetur turpis nec dolor laoreet ultrices. In a venenatis leo.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-item">
                    <div class="icon">
                        <i class="icofont-cloudy"></i>
                    </div>
                    <h3>Engaging Tours</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean gravida leo porttitor pretium ullamcorper. Fusce consectetur turpis nec dolor laoreet ultrices. In a venenatis leo.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Next Generation Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <span>Fitness Tracking</span>
                    <h3>Get fit with a tracker that's more than a bit smarter</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box modern-flexible">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-item right-icon">
                        <div class="icon">
                            <i class="icofont-lamp-light"></i>
                        </div>
                        <h3>Stunning Showcases</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item right-icon">
                        <div class="icon">
                            <i class="icofont-responsive"></i>
                        </div>
                        <h3>Fully Customizable</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item right-icon">
                        <div class="icon">
                            <i class="icofont-ui-timer"></i>
                        </div>
                        <h3>Engaging Tours</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>
                </div>

                <div class="col-lg-4 overview-img">
                    <img src="assets/img/feature-img-with-app.png" alt="image">
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-item">
                        <div class="icon">
                            <i class="icofont-ui-timer"></i>
                        </div>
                        <h3>Engaging Tours</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item">
                        <div class="icon">
                            <i class="icofont-star-alt-2"></i>
                        </div>
                        <h3>Exquisite Design</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>

                    <div class="single-item">
                        <div class="icon">
                            <i class="icofont-ui-timer"></i>
                        </div>
                        <h3>Engaging Tours</h3>
                        <p>Nam fermentum, arcu nec porta aliquam, lacus lorem efficitur lectus, quis sagittis tellus erat quis.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <span>Instant Video Call and live chat</span>
                    <h3>Everything you nedd, one swipe away.</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/about-us" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-4.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How it Works</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-brand-android-robot"></i>
                    </div>
                    <h3>Install App</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-responsive"></i>
                    </div>
                    <h3>Connect Device</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-ui-settings"></i>
                    </div>
                    <h3>Configure it</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3>Yay!Done</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Gallery Area -->
<section class="gallery-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>User Gallery</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>

    <div class="row m-0">
        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery1.jpg" alt="gallery">
                <a href="assets/img/gallery1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery2.jpg" alt="gallery">
                <a href="assets/img/gallery2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery3.jpg" alt="gallery">
                <a href="assets/img/gallery3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery4.jpg" alt="gallery">
                <a href="assets/img/gallery4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery5.jpg" alt="gallery">
                <a href="assets/img/gallery5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery6.jpg" alt="gallery">
                <a href="assets/img/gallery6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery7.jpg" alt="gallery">
                <a href="assets/img/gallery7.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 p-0">
            <div class="single-image">
                <img src="assets/img/gallery8.jpg" alt="gallery">
                <a href="assets/img/gallery8.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->

<!-- Start Product Area -->
<section class="product-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Product</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="product-slides owl-carousel owl-theme">
            <div class="single-product">
                <div class="product-img">
                    <img src="assets/img/shop-item1.jpg" alt="item">
                    <a routerLink="/" class="add-to-cart-btn">Add to Cart <i class="icofont-shopping-cart"></i></a>
                </div>

                <div class="product-content">
                    <h3><a routerLink="/">Drop Side Watch</a></h3>
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5 col-6">
                            <h5>$8.50 <span>$12.00</span></h5>
                        </div>
                        <div class="col-lg-7 col-6">
                            <ul>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-product">
                <div class="product-img">
                    <img src="assets/img/shop-item2.jpg" alt="item">
                    <a routerLink="/" class="add-to-cart-btn">Add to Cart <i class="icofont-shopping-cart"></i></a>
                </div>

                <div class="product-content">
                    <h3><a routerLink="/">Drop Side Watch</a></h3>
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5 col-6">
                            <h5>$8.50 <span>$12.00</span></h5>
                        </div>
                        <div class="col-lg-7 col-6">
                            <ul>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-product">
                <div class="product-img">
                    <img src="assets/img/shop-item3.jpg" alt="item">
                    <a routerLink="/" class="add-to-cart-btn">Add to Cart <i class="icofont-shopping-cart"></i></a>
                </div>

                <div class="product-content">
                    <h3><a routerLink="/">Drop Side Watch</a></h3>
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5 col-6">
                            <h5>$8.50 <span>$12.00</span></h5>
                        </div>
                        <div class="col-lg-7 col-6">
                            <ul>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-product">
                <div class="product-img">
                    <img src="assets/img/shop-item4.jpg" alt="item">
                    <a routerLink="/" class="add-to-cart-btn">Add to Cart <i class="icofont-shopping-cart"></i></a>
                </div>

                <div class="product-content">
                    <h3><a routerLink="/">Drop Side Watch</a></h3>
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5 col-6">
                            <h5>$8.50 <span>$12.00</span></h5>
                        </div>
                        <div class="col-lg-7 col-6">
                            <ul>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-product">
                <div class="product-img">
                    <img src="assets/img/shop-item5.jpg" alt="item">
                    <a routerLink="/" class="add-to-cart-btn">Add to Cart <i class="icofont-shopping-cart"></i></a>
                </div>

                <div class="product-content">
                    <h3><a routerLink="/">Drop Side Watch</a></h3>
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5 col-6">
                            <h5>$8.50 <span>$12.00</span></h5>
                        </div>
                        <div class="col-lg-7 col-6">
                            <ul>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-product">
                <div class="product-img">
                    <img src="assets/img/shop-item6.jpg" alt="item">
                    <a routerLink="/" class="add-to-cart-btn">Add to Cart <i class="icofont-shopping-cart"></i></a>
                </div>

                <div class="product-content">
                    <h3><a routerLink="/">Drop Side Watch</a></h3>
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5 col-6">
                            <h5>$8.50 <span>$12.00</span></h5>
                        </div>
                        <div class="col-lg-7 col-6">
                            <ul>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                                <li><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Product Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 23, 2024</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2024</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 14, 2024</li>
                    </ul>
                    <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 06, 2024</li>
                    </ul>
                    <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 04, 2024</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Apps in 2024</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 26, 2024</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2024</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- Start App Download Area -->
<section class="app-download ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6">
                <div class="section-title">
                    <h2>Download Our Apps Today</h2>
                    <div class="bar"></div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account the system and expound the actual teachings of the great explore.</p>
                    <div class="download-btn">
                        <a href="#" target="_blank"><i class="icofont-brand-android-robot"></i> Available On <span>Google Store</span></a>
                        <a href="#" target="_blank"><i class="icofont-brand-apple"></i> Available On <span>Apple Store</span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-image">
                    <img src="assets/img/app-img1.png" alt="image">
                    <img src="assets/img/app-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Download Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">
                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/slack.png" alt="mailchimp">
                    <h3>Slack</h3>
                    <span>Messaging</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/twitter.png" alt="mailchimp">
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/instagram.png" alt="mailchimp">
                    <h3>Instagram</h3>
                    <span>News Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->