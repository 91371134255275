import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-partner",
    templateUrl: "./partner.component.html",
    styleUrls: ["./partner.component.scss"],
})
export class PartnerComponent implements OnInit {
    constructor() {}

    ngOnInit() {
      
    }
}
