import { Component, HostListener, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "app-gallery",
    templateUrl: "./gallery.component.html",
    styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit {
    screenWidth: number;
    constructor(private titleService: Title) {
        this.titleService.setTitle("Agilisys Maroc - Nos clients");
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.screenWidth = window.innerWidth;
    }
    ngOnInit() {}
}
